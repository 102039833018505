(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/playmaker-widget-goal/assets/javascripts/playmaker-widget-goal.js') >= 0) return;  svs.modules.push('/components/sport/playmaker-widget-goal/assets/javascripts/playmaker-widget-goal.js');
"use strict";


const {
  useEffect
} = React;
const {
  PM
} = window;
const {
  log
} = svs.core;
const logger = log.getLogger('sport:playmaker-widget');
const DEFAULT_SETTINGS = [
'averageScored', 'averageConceded', 'averageScoredBetween', 'averageScoredHomeAndAway', 'topScorersXG'];
const ALL_ENABLED = ['xG', 'xGC', ...DEFAULT_SETTINGS];
const PlaymakerWidgetGoal = _ref => {
  let {
    uniqueId,
    matchId
  } = _ref;
  const containerId = "pm_".concat(uniqueId, "_").concat(matchId);
  useEffect(() => {
    if (!matchId || !containerId) {
      return;
    }
    const settings = svs.core.detect.feature('ft-playmaker-xg-xgc') ? ALL_ENABLED : DEFAULT_SETTINGS;
    let instance;
    try {
      instance = PM('addGoalWidget', {
        selectorId: containerId,
        matchId,
        settings
      });
    } catch (error) {
      logger.error('Failed adding playmaker widget', error);
    }
    return () => {
      if (instance) {
        instance.remove();
      }
    };
  }, [matchId, containerId]);
  return React.createElement("div", {
    className: "playmaker_widget_goal"
  }, React.createElement("h2", {
    className: "playmaker_widget_goal__header"
  }, "M\xE5lstatistik"), React.createElement("div", {
    id: containerId
  }));
};
setGlobal('svs.components.sport.playmakerWidgetGoal.PlaymakerWidgetGoal', PlaymakerWidgetGoal);

 })(window);